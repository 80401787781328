import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import AboutHero from '../components/About/AboutHero/AboutHero'
import AboutContent from '../components/About/AboutContent/AboutContent'

class About extends React.Component {
  render() {
    const postNode = {
      title: `About - ${config.siteTitle}`,
    }

    return (
      <Layout>
        <Helmet>
          <title>{`About - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath="about" customTitle />
        <AboutHero />
        <AboutContent />
      </Layout>
    )
  }
}

export default About
