import React from 'react'
import './AboutContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import { FaLaptopCode, FaCoffee } from 'react-icons/fa'

export class AboutContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  render() {
    return (
      <div className="about-content">
        <div className="split light subheader-block">
          <div className="text-wrap">
            We're a energetic, growing consultancy with a passion for solving
            big, mission critical problems in the public and private sectors
          </div>
        </div>
        <div className="split whitebg content-block">
          <div className="image-wrap">
            <div
              className="image"
              style={{ backgroundImage: 'url("/about1.jpg")' }}
            />
          </div>
          <div className="text-wrap">
            <div className="text-wrap-inner">
              <div className="title">
                Work on big problems with great people
              </div>
              <div className="description">
                Our customers hail from a diverse, ever growing list of
                industries. We work with small startups, hypergrowth companies,
                and some of the largest enterprises on the planet including
                Federal Agencies and Fortune 500 companies
              </div>
            </div>
          </div>
        </div>
        <div className="split light subheader-block">
          <div className="text-wrap">
            Free training, certification incentives, and much more make Rackner
            an amazing place to work and learn. We are a remote-first company
            with an HQ in the DC Metro Area and geographical presence across much of the continental US.
          </div>
        </div>
        <div className="split whitebg content-block border">
          <div className="text-wrap">
            <div className="text-wrap-inner">
              <div className="title">Fast Paced Culture</div>
              <div className="description">
                We're always learning and incorporating new things. We stay on
                the bleeding edge, appraising new technologies and incorporating
                them if they provide additional value to our customers.
              </div>
            </div>
          </div>
          <div className="image-wrap">
            <div
              className="image"
              style={{ backgroundImage: 'url("/laptop-code.jpg")' }}
            />
          </div>
        </div>
        <CallToActionBanner
          ahref={'https://boards.greenhouse.io/rackner'}
          icon={FaLaptopCode}
          linkText={'View Job Postings'}
        />
      </div>
    )
  }
}

export default AboutContent
